<template>
  <div>
    <div class="my-5">

      <router-link v-if="!loading" :to="{
        name: 'products',
        params: { companyUrl: company.url, uuid: company.uuid }
      }" class="flex items-center text-gray-700">
        
        
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd"
            d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
            clip-rule="evenodd" />
        </svg>
        <span class="text-xs md:text-sm"> ir para página inicial</span>
      </router-link>
    </div>
    <!-- lojas -->
    <section class="grid gap-4 sm:grid-cols-2 md:grid-cols-3 p-4 md:p-0 my-5" v-if="myOrders.data.length">
      <div v-for="(order, index) in myOrders.data" :key="index">
        <router-link :to="{ name: 'detail.order', params: { identify: order.identify } }">
          <div class="
              card__order
              flex flex-col
              md:flex-row
              p-2
              rounded
              border border-gray-300
            ">
            <div class=" flex items-start mr-5 flex-col">
              <p class="bg-gray-100 p-2 rounded">
                {{ order.date }}
              </p>
              <p class="text-lg font-extrabold text-gray-700">
                {{ order.company.name }}
              </p>
              <p class="text-sm text-gray-500">{{ order.identify }}</p>
              <p class="text-sm text-gray-500">
                Status: {{ order.status | formatStatus }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </section>

    <section v-else-if="!loading" class=" inset-0 h-full w-full">
      <div class="flex flex-col justify-center items-center h-full space-y-12">
        <img src="@/assets/images/cart_cleasr3.jpg" alt="sem pedidos" class="w-4/12" />
        <div class="flex flex-col justify-center items-center">
          <p class="text-gray-900">Você ainda não tem pedidos.</p>
          <p class="text-gray-500 text-sm">
            Aproveite as ofertas e faça seu primeiro pedido.
          </p>
        </div>
      </div>
    </section>

    <Loading :open="loading" :msg="msgLoading" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from "../../components/Loading";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      msgLoading: "Estamos buscando seus pedidos",
    };
  },
  created() {
    this.loading = true;

    this.getMyOrders()
      .then((response) => {
        this.loading = false;
      })
      .catch((error) => {
        this.$swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
          icon: "error",
          title: "Falha ao buscar pedidos",
        });
      });
  },

  computed: {
    ...mapState({
      myOrders: (state) => state.orders.myOrders,
      company: (state) => state.companies.companySelected,

    }),
  },

  methods: {
    ...mapActions(["getMyOrders"]),
  },

  filters: {
    formatStatus(item) {
      if (item == "pending") {
        return "Pendente";
      }
      if (item == "accepted") {
        return "Aceito";
      }
      if (item == "delivery") {
        return "Saiu para entrega";
      }

      if (item == "concluded") {
        return "Concluído";
      }

      if (item == "in-preparation") {
        return "Em preparo";
      }

      if (item == "canceled" || item == "rejected") {
        return "Cancelado";
      }
    },
  },
};
</script> 

